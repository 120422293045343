import {testIdProps} from '@github-ui/test-id-props'
import {ChevronDownIcon, ChevronRightIcon} from '@primer/octicons-react'
import {Heading, IconButton} from '@primer/react'
import {clsx} from 'clsx'
import type {ReactNode} from 'react'

import {useNestedListViewProperties} from '../context/PropertiesContext'
import {useNextHeaderTag} from '../hooks/use-next-header-tag'
import styles from './Title.module.css'

export type NestedListViewHeaderTitleProps = {
  title: string
  children?: ReactNode
  className?: string
}

export const NestedListViewHeaderTitle = ({title, children, className}: NestedListViewHeaderTitleProps) => {
  const titleTag = useNextHeaderTag('nested-list-view-metadata')
  const {isCollapsible, isExpanded, setIsExpanded} = useNestedListViewProperties()

  return (
    <div className={clsx(styles.container, className)}>
      <Heading as={titleTag} className={styles.header} {...testIdProps('nested-list-view-header-title')}>
        {/* Add icon button component to expand or collapse the nested list view depending on the isCollapsible prop */}
        {isCollapsible && (
          <IconButton
            icon={isExpanded ? ChevronDownIcon : ChevronRightIcon}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-label={isExpanded ? `Collapse ${title}` : `Expand ${title}`}
            size="small"
            variant="invisible"
            aria-expanded={isExpanded}
            className={styles.expandButton}
            tooltipDirection="n"
          />
        )}
        {title}
      </Heading>
      {children}
    </div>
  )
}

try{ NestedListViewHeaderTitle.displayName ||= 'NestedListViewHeaderTitle' } catch {}