/**
 * @generated SignedSource<<57eb0094b8f6079ceae4d886fdd1142b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewIssueTimelineIssueFragment$data = {
  readonly id: string;
  readonly repository: {
    readonly id: string;
  };
  readonly url: string;
  readonly " $fragmentSpreads": FragmentRefs<"NewIssueTimelineBackFragment" | "NewIssueTimelineFrontFragment">;
  readonly " $fragmentType": "NewIssueTimelineIssueFragment";
};
export type NewIssueTimelineIssueFragment$key = {
  readonly " $data"?: NewIssueTimelineIssueFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewIssueTimelineIssueFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "count",
    "value": 15
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewIssueTimelineIssueFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Repository",
      "kind": "LinkedField",
      "name": "repository",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "NewIssueTimelineFrontFragment"
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "NewIssueTimelineBackFragment"
    }
  ],
  "type": "Issue",
  "abstractKey": null
};
})();

(node as any).hash = "bc4f3bbdb29e3befee3b8fb5d3bc4c24";

export default node;
