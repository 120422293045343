import {graphql, readInlineData, useFragment, usePaginationFragment, type RefetchFnDynamic} from 'react-relay'
import type {NewIssueTimelineItem$data, NewIssueTimelineItem$key} from './__generated__/NewIssueTimelineItem.graphql'
import {useEffect, useMemo, useRef, useState, type ReactNode} from 'react'
import {Timeline} from '@primer/react'
import type {
  NewIssueTimelineFrontFragment$data,
  NewIssueTimelineFrontFragment$key,
} from './__generated__/NewIssueTimelineFrontFragment.graphql'
import type {NewIssueTimelineIssueFragment$key} from './__generated__/NewIssueTimelineIssueFragment.graphql'
import type {
  NewIssueTimelineBackFragment$data,
  NewIssueTimelineBackFragment$key,
} from './__generated__/NewIssueTimelineBackFragment.graphql'
import type {IssueViewerIssue$data} from '../__generated__/IssueViewerIssue.graphql'
import type {NewTimelinePaginationBackQuery} from './__generated__/NewTimelinePaginationBackQuery.graphql'
import {useIssueViewerSubscription} from '../IssueViewerSubscription'
import {LABELS} from '@github-ui/timeline-items/Labels'
import {rollupEvents, type RolledUpTimelineItem} from '../../utils/timeline-rollups'
import {LoadMore, type LoadMoreCallbackFn} from './LoadMore'
import {isEventHighlighted, useTimelineHighlights} from './use-timeline-highlight'
import type {useTimelineHighlightItems$data} from './__generated__/useTimelineHighlightItems.graphql'
import {NewIssueTimelineItem, TimelineItemFragment, type EventProps} from './NewIssueTimelineItem'
import {FailedLoadTimelineItem} from './FailedLoadTimelineItem'
import {TimelineTransferringFlash} from './TimelineTransferringFlash'
import type {IssueTimelineSecondary$key} from '../__generated__/IssueTimelineSecondary.graphql'
import type {NewTimelinePaginationFrontQuery} from './__generated__/NewTimelinePaginationFrontQuery.graphql'
import {getHighlightedEvent} from '@github-ui/timeline-items/HighlightedEvent'
import {useNewScrollToHighlighted} from '../../hooks/use-scroll-to-highlighted'
// Using getFocusableChild for a callback after client side loading, won't affect SSR
// eslint-disable-next-line no-restricted-imports
import {getFocusableChild} from '@primer/behaviors/utils'
import {VALUES} from '@github-ui/timeline-items/Values'
import {isFeatureEnabled} from '@github-ui/feature-flags'

type TimelineNode = NonNullable<
  NonNullable<NonNullable<NewIssueTimelineFrontFragment$data['frontTimelineItems']['edges']>[0]>['node']
>
type LoadItem = {
  type: 'load'
  position: 'top' | 'bottom'
  loadFromTop: LoadMoreCallbackFn
  loadFromBottom: LoadMoreCallbackFn
  numberOfRemainingItems: number
}

type EventItem = {type: 'event'} & RolledUpTimelineItem<NewIssueTimelineItem$data>
type TimelineItem = EventItem | LoadItem

/**
 * Temporary type for parsing the timeline items and grouping them into section elements
 * for improve the accessibility of the event items by adding landmarks for navigation.
 */
type RenderItem = {
  /**
   * Controls whether the item has already been added to the grouped events or not.
   *
   * Only used in events and not in load items, graceful degratation items or issue comments.
   */
  isAddedToGroupedEvents: boolean
  timelineItem: TimelineItem
  render: ReactNode
}

const mainTimelineFragment = graphql`
  fragment NewIssueTimelineIssueFragment on Issue {
    id
    url
    repository {
      id
    }
    ...NewIssueTimelineFrontFragment @arguments(count: 15)
    ...NewIssueTimelineBackFragment @arguments(count: 15)
  }
`

const frontTimelineItemsFragment = graphql`
  fragment NewIssueTimelineFrontFragment on Issue
  @argumentDefinitions(count: {type: "Int"}, cursor: {type: "String"})
  @refetchable(queryName: "NewTimelinePaginationFrontQuery") {
    frontTimelineItems: timelineItems(first: $count, visibleEventsOnly: true, after: $cursor)
      @defer(label: "Issue__frontTimelineItems")
      @connection(key: "Issue__frontTimelineItems") {
      pageInfo {
        hasNextPage
      }
      totalCount
      edges {
        node {
          __id
          ...NewIssueTimelineItem
        }
      }
    }
  }
`

const backTimelineItemsFragment = graphql`
  fragment NewIssueTimelineBackFragment on Issue
  @argumentDefinitions(count: {type: "Int"}, cursor: {type: "String"})
  @refetchable(queryName: "NewTimelinePaginationBackQuery") {
    backTimelineItems: timelineItems(last: $count, visibleEventsOnly: true, before: $cursor)
      @defer(label: "Issue__backTimelineItems")
      @connection(key: "Issue__backTimelineItems") {
      pageInfo {
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          __id
          ...NewIssueTimelineItem
        }
      }
    }
  }
`

type NewIssueTimelineProps = {
  issue: IssueViewerIssue$data
  issueSecondary: IssueTimelineSecondary$key | undefined
  highlightedEvent: string | undefined
} & EventProps
export const NewIssueTimeline = ({
  issue,
  issueSecondary,
  viewer,
  highlightedEvent,
  onCommentChange,
  onCommentReply,
  onCommentEditCancel,
  optionConfig,
}: NewIssueTimelineProps) => {
  const data = useFragment<NewIssueTimelineIssueFragment$key>(mainTimelineFragment, issue)
  const secondaryData = useFragment(
    graphql`
      fragment NewIssueTimelineSecondary on Issue {
        isTransferInProgress
      }
    `,
    issueSecondary,
  )

  const {
    data: {frontTimelineItems: frontTimelineData},
    loadNext: loadMoreFrontItems,
    refetch: refetchFrontItems,
  } = usePaginationFragment<NewTimelinePaginationFrontQuery, NewIssueTimelineFrontFragment$key>(
    frontTimelineItemsFragment,
    data,
  )
  const {
    data: {backTimelineItems: backTimelineData},
    loadPrevious: loadMoreBackItems,
    refetch: refetchBackItems,
  } = usePaginationFragment<NewTimelinePaginationBackQuery, NewIssueTimelineBackFragment$key>(
    backTimelineItemsFragment,
    data,
  )

  const [frontRefetched, setFrontRefetched] = useState(false)
  const [backRefetched, setBackRefetched] = useState(false)
  const isCacheFixWorkaroundEnabled = isFeatureEnabled('issues_react_cache_fix_workaround')

  const mapAndFilterTimelineItems = (
    timelineData:
      | NewIssueTimelineFrontFragment$data['frontTimelineItems']
      | NewIssueTimelineBackFragment$data['backTimelineItems']
      | useTimelineHighlightItems$data['timelineItems']
      | undefined,
    refetch?: RefetchFnDynamic<
      NewTimelinePaginationFrontQuery | NewTimelinePaginationBackQuery,
      NewIssueTimelineFrontFragment$key | NewIssueTimelineBackFragment$key
    >,
    refetched?: boolean,
    setRefetched?: (value: boolean) => void,
  ) => {
    if (!timelineData) {
      // Workaround until we find a way to fix an issue where the data is null when we have the data in the browser cache
      // but it's marked as stale by Relay. See https://github.com/github/issues/issues/13005
      if (refetch !== undefined && !refetched && setRefetched !== undefined && isCacheFixWorkaroundEnabled) {
        refetch({}, {fetchPolicy: 'network-only', onComplete: () => setRefetched(true)})
      }
      return []
    }
    return (timelineData.edges || [])
      .reduce((items, item) => {
        if (item?.node?.__id) items.push(item.node)

        return items
      }, [] as TimelineNode[])
      .map(item => {
        // eslint-disable-next-line no-restricted-syntax
        return readInlineData<NewIssueTimelineItem$key>(TimelineItemFragment, item)
      })
  }

  const frontItems = mapAndFilterTimelineItems(frontTimelineData, refetchFrontItems, frontRefetched, setFrontRefetched)
  const backItems = mapAndFilterTimelineItems(backTimelineData, refetchBackItems, backRefetched, setBackRefetched)

  const totalItemCount = frontTimelineData?.totalCount ?? 0

  if (optionConfig.withLiveUpdates && !!viewer) {
    // Live updates are still not production ready, so we are forced to conditionally render this hook via a FF
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useIssueViewerSubscription(issue.id, totalItemCount, 'Issue__backTimelineItems')
  }

  const highlight = useMemo(() => getHighlightedEvent(highlightedEvent), [highlightedEvent])
  const highlightIsPreloaded = useMemo(
    () => (highlight ? [...frontItems, ...backItems].some(event => isEventHighlighted(event, highlight)) : false),
    [backItems, frontItems, highlight],
  )

  const {
    data: highlightData,
    loadPrevious: loadBeforeHighlight,
    totalBeforeFocus,
    loadNext: loadAfterHighlight,
    totalAfterFocus,
  } = useTimelineHighlights(issue.id, highlight, highlight && !highlightIsPreloaded)

  const loadedHighlightItems = useMemo(
    () => (highlightData ? mapAndFilterTimelineItems(highlightData) : []),
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [highlightData],
  )
  const hasLazyLoadedHighlights = loadedHighlightItems.length > 0

  const loadedFrontCount = frontItems.length
  const loadedBackCount = backItems.length

  const itemsRemainingFront = Math.max(
    hasLazyLoadedHighlights
      ? (totalBeforeFocus || 0) - loadedFrontCount
      : totalItemCount - frontItems.length - backItems.length,
    0,
  )
  const itemsRemainingBack = Math.max(
    hasLazyLoadedHighlights
      ? (totalAfterFocus || 0) - loadedBackCount
      : totalItemCount - frontItems.length - backItems.length,
    0,
  )

  // This state controls if the highlight borders should be shown or not
  const [shouldHighlightElement, setShouldHighlightElement] = useState<boolean>(highlight !== undefined)

  // After rendering, clicking anywhere should remove the borders
  useEffect(() => {
    const handlePageClick = () => {
      setShouldHighlightElement(false)
    }
    document.addEventListener('click', handlePageClick)

    return () => {
      document.removeEventListener('click', handlePageClick)
    }
  }, [highlight])

  // If a new element's link is clicked, we should restore the highlight
  useEffect(() => {
    if (!highlight?.id) return

    setShouldHighlightElement(true)
  }, [highlight?.id])

  /**
   * Takes a list of timeline items and dedupes them based on their __id
   */
  const dedupeItems = (items: NewIssueTimelineItem$data[]) =>
    items.reduce(
      ({keys, values}, item) => {
        if (!keys.has(item.__id)) {
          keys.add(item.__id)
          values.push(item)
        }

        return {keys, values}
      },
      {keys: new Set<string>(), values: [] as NewIssueTimelineItem$data[]},
    ).values

  const timelineItems: TimelineItem[] = useMemo(() => {
    // If we loaded duplicated records from the front and back load, we dedupe them to prevent accidental duplicates
    //
    // This can happen if for some reason we get more items than there is in the issue
    // like when an event is deleted or a mismatch from live updates
    if (frontItems.length + loadedHighlightItems.length + backItems.length >= totalItemCount) {
      return rollupEvents(dedupeItems([...frontItems, ...loadedHighlightItems, ...backItems])).map(item => ({
        type: 'event',
        ...item,
      }))
    }

    // Otherwise we render both arrays and inject the load buttons in the middle
    let allItems: TimelineItem[] = rollupEvents(frontItems).map(item => ({
      type: 'event',
      ...item,
    }))

    if (itemsRemainingFront > 0) {
      allItems.push({
        type: 'load',
        position: 'top',
        loadFromTop: loadMoreFrontItems,
        loadFromBottom: (count, options) => {
          if (hasLazyLoadedHighlights) {
            loadBeforeHighlight(count, options)
          } else {
            loadMoreBackItems(count, options)
          }
        },
        numberOfRemainingItems: itemsRemainingFront,
      })
    }

    if (hasLazyLoadedHighlights) {
      allItems = allItems.concat(
        rollupEvents(loadedHighlightItems).map(item => ({
          type: 'event',
          ...item,
        })),
      )

      if (itemsRemainingBack > 0) {
        allItems.push({
          type: 'load',
          position: 'bottom',
          loadFromTop: loadAfterHighlight,
          loadFromBottom: loadMoreBackItems,
          numberOfRemainingItems: itemsRemainingBack,
        })
      }
    }

    allItems = allItems.concat(
      rollupEvents(backItems).map(item => ({
        type: 'event',
        ...item,
      })),
    )

    return allItems
  }, [
    frontItems,
    loadedHighlightItems,
    backItems,
    totalItemCount,
    itemsRemainingFront,
    hasLazyLoadedHighlights,
    loadMoreFrontItems,
    loadBeforeHighlight,
    loadMoreBackItems,
    itemsRemainingBack,
    loadAfterHighlight,
  ])

  // Iterate items to check if the loaded highlight is in the list
  // Room for performance optimization if we face issues in large timelines, as we can determine
  // if the highlighted item is already present during the mapping process.
  // https://github.com/github/github/blob/9ecd32f60f96a519f99462c48f6235d5842ca814/ui/packages/issue-viewer/components/timeline/NewIssueTimeline.tsx#L249
  const highlightedItemRef = useRef<HTMLDivElement>(null)
  const isHighlightLoaded = useMemo(() => {
    if (!shouldHighlightElement || !highlight) return false

    return timelineItems.some(item => {
      if (item.type !== 'event' || !item.item) return false

      return isEventHighlighted(item.item, highlight)
    })
  }, [highlight, timelineItems, shouldHighlightElement])

  useNewScrollToHighlighted(isHighlightLoaded, highlightedItemRef, highlightedEvent)

  const focusFirstLoadedItem = (items: TimelineItem[], currentIndex: number) => {
    // A timeout is needed to trigger the focusing asynchronously
    // so it doesn't happen before Relay loads the new timeline items
    setTimeout(() => {
      // We want to use the previous item as a base, since the load button
      // will get pushed down and/or unmounted, when all items are loaded.
      const baseItem = items[currentIndex - 1]
      if (!baseItem || baseItem.type !== 'event' || !baseItem.item?.__id) return

      // To avoid complex propagation of refs, we instead query via data attributes set
      // in the TimelineRowBorder component and take the next element, which should be
      // the first of the newly loaded events.
      const nextItemSelector = `[${VALUES.timeline.dataTimelineEventId}="${baseItem.item.__id}"] + *`
      const eventElement = document.querySelector<HTMLElement>(nextItemSelector)
      if (!eventElement) return

      const focusableElement = getFocusableChild(eventElement)
      focusableElement?.focus({preventScroll: true})
    })
  }

  return (
    <>
      {secondaryData?.isTransferInProgress && <TimelineTransferringFlash />}
      <h2 className="sr-only">{LABELS.timeline.header}</h2>
      <Timeline>
        {timelineItems
          .map((timelineItem: TimelineItem, index, initialTimelineItems): RenderItem => {
            if (timelineItem.type === 'load') {
              const fullType: 'load-top' | 'load-bottom' = `${timelineItem.type}-${timelineItem.position}`
              return {
                isAddedToGroupedEvents: false,
                timelineItem,
                render: (
                  <LoadMore
                    key={fullType}
                    type={fullType}
                    loadFromTopFn={timelineItem.loadFromTop}
                    loadFromBottomFn={timelineItem.loadFromBottom}
                    numberOfRemainingItems={timelineItem.numberOfRemainingItems}
                    lastItemInTopTimelineIsComment
                    firstItemInBottomTimelineIsComment
                    onLoadAllComplete={() => focusFirstLoadedItem(initialTimelineItems, index)}
                  >
                    Load more
                  </LoadMore>
                ),
              }
            }

            if (timelineItem.item == null) {
              return {
                isAddedToGroupedEvents: false,
                timelineItem,
                render: <FailedLoadTimelineItem key="failed-load-item" />,
              }
            }

            const isHighlighted =
              shouldHighlightElement && highlight && isEventHighlighted(timelineItem.item, highlight)
            return {
              isAddedToGroupedEvents: false,
              timelineItem,
              render: (
                <NewIssueTimelineItem
                  key={timelineItem.item.__id}
                  item={timelineItem}
                  issueId={data.id}
                  repositoryId={data.repository.id}
                  issueUrl={data.url}
                  viewer={viewer}
                  onCommentChange={onCommentChange}
                  onCommentReply={onCommentReply}
                  onCommentEditCancel={onCommentEditCancel}
                  refAttribute={isHighlighted ? highlightedItemRef : undefined}
                  optionConfig={optionConfig}
                  isHighlighted={isHighlighted}
                />
              ),
            }
          })
          .reduce((newArr, currentItem, currentIndex, allItems) => {
            // This is grouping event items that are rendered next to eachother
            // inside <section> tags for adding the correct landmarks for screenreaders
            //
            // All events that are not comments, will be grouped.
            //
            // https://github.com/github/accessibility/issues/5224#issuecomment-1846919306

            if (currentItem.isAddedToGroupedEvents) return newArr
            if (
              currentItem.timelineItem.type !== 'event' ||
              currentItem.timelineItem.item?.__typename === 'IssueComment'
            ) {
              currentItem.isAddedToGroupedEvents = true
              newArr.push(currentItem.render)
              return newArr
            }

            const endSectionIndex = allItems.findIndex((renderItem, index) => {
              return (
                index > currentIndex &&
                (renderItem.timelineItem.type !== 'event' ||
                  renderItem.timelineItem.item?.__typename === 'IssueComment')
              )
            })
            const sectionElement = (
              <section key={`events-${currentItem.timelineItem.item?.__id}`} aria-label="Events">
                {allItems.slice(currentIndex, endSectionIndex > -1 ? endSectionIndex : undefined).map(item => {
                  item.isAddedToGroupedEvents = true
                  return item.render
                })}
              </section>
            )

            newArr.push(sectionElement)
            return newArr
          }, [] as ReactNode[])}
      </Timeline>
    </>
  )
}

try{ NewIssueTimeline.displayName ||= 'NewIssueTimeline' } catch {}