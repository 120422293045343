export const MESSAGES = {
  couldNotLoad: `Couldn't load`,
  issueNotFound: 'Issue not found',
  issueNotFoundDescription: 'This issue does not exist or has been deleted.',
  deleteIssueConfirmationTitle: 'Delete issue?',
  deleteIssueConfirmationActionPoints: [
    'This cannot be undone',
    'Only adminstrators can delete issues',
    'Deletion will remove the issue from search and previous references will point to a placeholder',
  ],
  deleteIssueConfirmationButton: 'Delete',
  issueTimelineInTransfer: 'Issue timeline is being transferred.',
  notSubscribedDescription:
    'Only receive notifications from this issue when you have participated or have been @mentioned.',
  subscribedDescription: 'Receive all notifications from this issue.',
  customDescription:
    'You will only be notified for events selected from the list below. If you participate or are @mentioned you will be subscribed.',
  subscribedToClosedDescription: 'You will be notified when this issue is closed.',
  subscribedToReopenedDescription: 'You will be notified when this issue is reopened.',
  customSelectionMissing: 'Please select at least one custom event to subscribe to.',
}
