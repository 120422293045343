/**
 * @generated SignedSource<<894887eeb53ef3a8bee51bdd41be18c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IssueViewerSecondaryIssueData$data = {
  readonly discussion: {
    readonly url: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderParentTitle" | "HeaderSecondary" | "HeaderSubIssueSummary" | "IssueBodyHeaderSecondaryFragment" | "IssueBodySecondaryFragment" | "IssueCommentComposerSecondary" | "IssueSidebarLazySections" | "IssueSidebarSecondary" | "IssueTimelineSecondary" | "NewIssueTimelineSecondary" | "SubIssuesCreateDialog" | "SubIssuesList" | "TaskListStatusFragment" | "TrackedByFragment">;
  readonly " $fragmentType": "IssueViewerSecondaryIssueData";
};
export type IssueViewerSecondaryIssueData$key = {
  readonly " $data"?: IssueViewerSecondaryIssueData$data;
  readonly " $fragmentSpreads": FragmentRefs<"IssueViewerSecondaryIssueData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "useNewTimeline"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "IssueViewerSecondaryIssueData",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderSecondary"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderParentTitle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IssueCommentComposerSecondary"
    },
    {
      "condition": "useNewTimeline",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "IssueTimelineSecondary"
        }
      ]
    },
    {
      "condition": "useNewTimeline",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NewIssueTimelineSecondary"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IssueSidebarLazySections"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IssueSidebarSecondary"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaskListStatusFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TrackedByFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IssueBodyHeaderSecondaryFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IssueBodySecondaryFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubIssuesList"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubIssuesCreateDialog"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderSubIssueSummary"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Discussion",
      "kind": "LinkedField",
      "name": "discussion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Issue",
  "abstractKey": null
};

(node as any).hash = "47a2f5492e91aa6c4afeef4a8330dc81";

export default node;
